<template>
     <div class="more_terBid">

          <div class="terBid_con">
               <div class="breadcrumb flex_row_start_center">
                    <router-link to="/bid/index">
                         <div class="crumb">招标信息</div>
                    </router-link>
                    <span class="el-icon-arrow-right"></span>
                    <div class="crumb last">{{typeText}}终止公告</div>

               </div>
               <div class="terBid_con_main">
                    <div class="list_item flex_row_between_center" v-for="(item,index) in list" :key="index">
                         <div class="m_first flex_row_start_center">
                              <div class="dot"></div>
                              <div class="title" @click="seeDetail(item)">{{item.noticeTitle}}</div>
                         </div>
                         <div class="m_time">{{item.createTime&&item.createTime.split(' ')[0]}}</div>
                    </div>

                    <!-- 分页 -->
                    <div class="flex_row_center_center sld_pagination">
                         <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                              :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                              :hide-on-single-page="true">
                         </el-pagination>
                    </div>
               </div>

          </div>
     </div>
</template>

<script>
     import { ref, reactive, getCurrentInstance, toRefs, onMounted } from 'vue'
     import { useRoute, useRouter } from 'vue-router'
     export default {
          setup() {
               const { proxy } = getCurrentInstance()
               const route = useRoute()
               const router = useRouter()
               const bidList = reactive({
                    list: [],
                    page: {}
               })

               const current = ref(1)

               const typeMap = reactive({
                    1: '货物招标',
                    2: '工程招标',
                    3: '服务招标'
               })

               const typeText = typeMap[route.query.type]

               const getBidList = () => {

                    let param = {
                         noticeType: 2,
                         pageSize: 10,
                         current: current.value,
                         type: route.query.type
                    }

                    proxy.$get('v3/business/front/enterBid/list', param).then(res => {
                         if (res.state == 200) {
                              bidList.list = res.data.list
                              bidList.page = res.data.pagination
                         }
                    })
               }


               const seeDetail = (item) => {
                    let { endId, bidId } = item
                    router.push({
                         path: '/bid/terBidNoti',
                         query: {
                              endId, bidId
                         }
                    })
               }


               const handleCurrentChange = (e) => {
                    current.value = e
                    getBidList()
               }

               onMounted(() => {
                    getBidList()
               })


               return {
                    bidList,
                    typeText,
                    seeDetail,
                    ...toRefs(bidList),
                    handleCurrentChange
               }
          }
     }
</script>

<style lang="scss">
     .more_terBid {
          background: #F5F5F5;
          padding-top: 20px;
          width: 100%;
          padding-bottom: 30px;

          .breadcrumb {
               font-size: 12px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               margin-bottom: 20px;

               .crumb {
                    color: #777777;
                    cursor: pointer;

               }

               .last {
                    color: #333333;
               }
          }


          .terBid_con {
               width: 1200px;
               margin: 0 auto;


               .terBid_con_main {
                    background: #FFFFFF;
                    padding: 20px;

               }

               .list_item {
                    margin-bottom: 22px;

                    .m_first {
                         .dot {
                              width: 8px;
                              height: 8px;
                              background: #CCCCCC;
                              border-radius: 50%;
                         }

                         .title {
                              height: 19px;
                              font-size: 15px;
                              font-family: Microsoft YaHei;
                              font-weight: 400;
                              color: #333333;
                              margin-left: 10px;
                              cursor: pointer;

                              &:hover {
                                   color: $colorMain;
                                   text-decoration: underline;
                              }
                         }
                    }

                    .m_time {
                         font-size: 14px;
                         font-family: Microsoft YaHei;
                         font-weight: 400;
                         color: #999999;
                    }
               }
          }
     }
</style>